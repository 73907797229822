/* eslint-disable */
import * as types from 'constants/userTypes';

const INITIALSTATE = {
    isInit: false,
    userData: {
        id: '',

        language: 'RUS',
        gender: 'm',
        phone: '',
        email: '',
        avatar: '',
        avatar_url: '',

        status: {
            id: '',
            cardNo: '',
            accNo: '',
        },

        channels: {
            email: {
                allowed: false,
                verified: true,
            },
            sms: {
                allowed: false,
                verified: true,
            },
            post: {
                allowed: false,
                verified: false,
            },
            phone: {
                allowed: true,
                verified: true,
            },
        },

        initials: {
            international: {
                name: '',
                secondName: '',
                surname: '',
            },
            original: {
                name: '',
                secondName: '',
                surname: '',
            },
        },

        security: {
            phoneLoginAllowed: true,
        },
        birthday: '',
        address: '',
        miles: 0,
        documents: [],

        // Notifications
        notifications: [],

        // Bonus miles
        bonusComplete: false, // Проверка на получение всех бонусов
        bonusSteps: [
            ['Имя', 200, false],
            ['Дата рождения', 200, false],
            ['Контактные данные', 300, false],
        ], // Массив шагов по бонусам

        creditcard: {
            bank: 'Tinkoff Bank',
            number: null,
            holder: null,
            expiry: null,
            cvc: null,
        },
    },
    sessions: {
        isFetching: false,
        list: [],
    },
    availableBenefits: [],
};

const user = (state = INITIALSTATE, payload) => {
    switch (payload.type) {
        case types.UPDATE_USER:
            return {
                ...state,
                isInit: true,
                userData: Object.assign({}, state.userData, payload.data),
            };

        case types.ADD_DOC_USER: {
            const docs = state.userData.documents;
            docs.push(payload.doc);

            return {
                ...state,
                userData: {
                    ...state.userData,
                    documents: docs,
                },
            };
        }

        case types.RESET_DATA:
            return INITIALSTATE;

        case types.SET_CONFIRM_INITIALS:
            return {
                ...state,
                confirmInitials: payload.fields,
            };

        case types.SET_SESSIONS:
            return {
                ...state,
                sessions: {
                    ...state.sessions,
                    isFetching: false,
                    list: payload.payload,
                },
            };

        case types.SET_AVAILABLE_BENEFITS:
            return {
                ...state,
                availableBenefits: payload.data,
            };
        default:
            return state;
    }
};

export default user;
