import metrics from 'managers/metrics';
import { v4 as uuidv4 } from 'uuid';

export const PLAUSIBLE_PARAM_NAME = 'x-plausible-uid';
const PLAUSIBLE_STORAGE_NAME = 'plausible:uid';

/**
 *  Data structures such as objects, arrays etc. aren't accepted by plausible
 *  see https://plausible.io/docs/custom-event-goals#using-custom-props */
function transformObjectValuesToString(obj) {
    return Object.fromEntries(
        Object.entries(obj).map(([key, value]) =>
            typeof value === 'object' && value !== null ? [key, JSON.stringify(value)] : [key, value]
        )
    );
}

export function getPlausibleUid() {
    let plausibleUuid;

    try {
        plausibleUuid = localStorage.getItem(PLAUSIBLE_STORAGE_NAME);

        if (!plausibleUuid) {
            throw new Error();
        }
    } catch {
        plausibleUuid = uuidv4();
        localStorage.setItem(PLAUSIBLE_STORAGE_NAME, plausibleUuid);
    }

    return plausibleUuid;
}

export function pushPlausibleEvent(eventName = '', eventData = {}) {
    if (!window.plausible) {
        metrics.pushEvent('plausible_not_loaded');

        return;
    }

    const plausibleUid = getPlausibleUid();

    window.plausible(eventName, {
        props: {
            platform: 'desktop',
            [PLAUSIBLE_PARAM_NAME]: plausibleUid,
            ...transformObjectValuesToString(eventData),
        },
    });
}

export function formatError(error = {}, errorText = null) {
    const { detail, status, type, code } = error;

    return {
        http_code: status,
        message: detail,
        text: errorText,
        type,
        code,
        last_screen: sessionStorage.getItem('prevScreen'),
    };
}
