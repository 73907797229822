import React from 'react';
import { t } from 'i18next';
// import { useSelector } from 'react-redux';
// import { getCanUserBuyStandby } from 'selectors/user';

const MobileMenuLink = ({ href, title }) => (
    <div className="header-nav-item _item">
        <a className="header-nav-link _link" href={href}>
            {t(title)}
        </a>
    </div>
);

export default function MobileMenu({ isOpen, onClose }) {
    return (
        <div className={`header-menu-mobile ${isOpen ? 'is-opened' : ''}`}>
            <div className={`overlay ${isOpen ? '-on' : ''}`} />
            <button
                className="header-menu-close js-header-menu-close reset-button-style"
                onClick={onClose}
                aria-label="close menu"
            />
            <div className="header-menu-inner">
                <nav className="header-nav js-header-nav">
                    <div className="header-nav-list _list">
                        <MobileMenuLink
                            href="https://media.utair.ru/?from=menu"
                            title={t('header.menu.where_fly_to')}
                        />
                        <MobileMenuLink
                            href="https://www.utair.ru/information/onlinepanel"
                            title={t('header.menu.flight_board')}
                        />
                        <MobileMenuLink
                            href="https://www.utair.ru/information/schedule/"
                            title={t('header.menu.flight_schedule')}
                        />
                        <MobileMenuLink
                            href="https://www.utair.ru/information/contacts/"
                            title={t('header.menu.contacts')}
                        />
                        <MobileMenuLink href="https://media.utair.ru/status" title={t('header.menu.bonus_program')} />
                        <MobileMenuLink href="https://www.utair.ru/support" title={t('header.menu.assistance')} />
                        <MobileMenuLink href="https://merch.utair.ru" title={t('header.menu.merch')} />
                    </div>
                </nav>
            </div>
        </div>
    );
}
