/* eslint-disable */
export const bonuses = {
    title: 'Бонусная программа Status',
    notice: 'Учитываются только полёты с картой Status. Если вы забыли указать номер карты при бронировании или регистрации - воспользуйтесь восстановлением миль',
    how_save_miles_faster: 'Как копить мили быстрее?',
    valid_until: 'Действует до',
    intro: {
        title: 'Мои мили и уровни',
        subtitle: 'Восстанавливайте мили и повышайте уровень Status',
    },
    my_flights: {
        title: 'Расходы на мои билеты в {{currentYear}} году',
        platinum_level:
            'Уровень в программе Utair Status зависит от суммы, потраченной на билеты в календарном году. Вы - Platinum! Пользуйтесь привилегиями программы Status в полном объеме.',
        current_level_equal_future:
            'Уровень в программе Utair Status зависит от суммы, потраченной на билеты в календарном году. Повышайте текущий уровень и сразу пользуйтесь новыми привилегиями. До элитного уровня {{nextLevel}} осталось {{rubleNeeded}}',
        current_level_over_future:
            'Уровень в программе Utair Status зависит от суммы, потраченной на билеты в календарном году. Подтвердите уровень на следующий год. Чтобы продлить срок действия уровня {{cardLevel}}, осталось {{differenceInRubles}}',
    },
    privilege_cards: {
        my_miles: 'Мои мили',
        family_miles: 'Мили семьи',
        all_rules: 'Все правила Status',
        accrual_history: 'История начислений',
        voucher_balance: 'Баланс ваучера',
        expire_date: 'До {{levelExpireDate}}',
        exchange_rate: '1 миля = 1 ₽',
        copied: 'Скопирован',
    },
    operations: {
        title: 'Операции с милями',
        transfer: 'Перевести мили',
        transfer_one: 'Перевести {{cardType}} миль',
        reestablish: 'Восстановить полёт',
        spend: 'Потратить',
        replenish: 'Пополнить',
        promo: 'Ввести промокод',
    },
    promo: {
        title: 'Ввод промокода',
        code: 'Промокод',
        success: 'Промокод успешно использован!',
        success_miles: 'Вам будет начислено {{miles}} миль в течение 5 минут',
        success_category: 'Вам присвоена категория: {{category}}',
        success_categories: 'Вам присвоены категории: {{category}}',
        fail: 'Промокод недействителен',
    },
    privileges_description: {
        call_center: 'Бесплатная приоритетная линия контакт-центра',
        baggage: 'Дополнительное место багажа (кроме тарифа Лайт и Минимум)',
        sets: 'Выбор места / Место+',
        bonuses: 'Начисление миль за полёты',
        reception: 'Стойка регистрации на рейс',
        miles_life_time: 'Срок действия миль',
        accrual: 'Начисление миль',
        priority: 'Приоритетная регистрация',
        business_room: 'Бизнес-зал, если вы купили билет эконом-класс',
        business_class_cc: 'Повышение в бизнес-класс через контакт-центр',
        business_class: 'Повышение в бизнес-класс на борту самолета',
        seats_when_buying: 'При покупке билета',
        seats_when_registration: 'При регистрации на рейс',
    },
    privileges_title: 'Привилегии',
    level: 'уровня',
    transactions: {
        title: 'Транзакции',
        search_placeholder: 'Название операции',
        recover_miles: 'Восстановить неучтенные полёты',
        find_data: 'Где найти данные на билете',
        hide_data: 'Скрыть билет с легендой',
        list: {
            operation: 'Операция',
            date: 'Дата',
            points: 'Мили',
            status: 'Статус',
            empty: 'Транзакции не найдены',
        },
        type: {
            airline_accrual: 'Начисление за перелёт {{departure}} ' + String.fromCharCode('8594') + ' {{arrival}}',
            non_airline_accrual: 'Начисление за неавиационные услуги',
            merge_income: 'Пополнение объединением',
            merge_outgoing: 'Списание объединением',
            burn: 'Сгорание миль',
            purchase: 'Покупка миль',
            expiration: 'Сгорание миль',
            redemption: 'Списание за неавиационные услуги',
            transfer_commission: 'Комиссия за перевод миль',
            household_redemption: 'Списание за внутренний перевод',
            household_accrual: 'Пополнение за внутренний перевод',
            manual_correction: 'Ручное изменение',
            transaction_correction: 'Изменение транзакции',
            other: 'Списание за ваучер',
            migration: 'Миграция баланса',
            conversion: 'Конвертация миль',
            forecast: '{{departure}} ' + String.fromCharCode('8594') + ' {{arrival}}',
            nar_ips: 'Списание за авиационные услуги',
            nar_order: 'Списание за заказ',
        },
        status: {
            confirmed: 'Обработано',
            pending: 'Ожидает обработки',
            canceled: 'Отменено',
            expired: 'Отменено',
        },
        error: {
            departure_date: 'Некорректная дата',
            more_12_months: 'C даты полёта может пройти не больше 12 месяцев',
            less_then_three_days: 'C даты полёта должно пройти 3 дня',
            flight_number: 'Некорректный номер рейса',
            ticket_number: 'Некорректный номер билета',
        },
    },
    inviting: {
        invite: 'Пригласить',
        action: 'Пригласи друга',
        bonus: 'и получи 1000 миль',
        condition: 'После его первого полёта.',
    },
    order_card: 'Заказать физическую карту',
    advertisement: {
        title: 'Копи мили с картой Сити банк',
        image_alt: 'Карта банка «Сити банк»',
        miles2000: {
            bonus: '2 000 миль начисляются',
            condition: 'после первой покупки по карте',
        },
        reward: {
            bonus: '2 наградные мили',
            condition: 'за каждые 50 рублей, оплаченные картой',
        },
    },
    recover: {
        title: 'Восстановление неучтенных полётов',
        departure_date: 'Дата рейса',
        departure_date_caption: 'не раньше 3 дней и не позднее 12 месяцев с даты полёта',
        warning:
            'Не указали карту Status при покупке билета?\nЗаполните данные рейса и восстановите историю полётов за последние 12 месяцев, чтобы быстрее достигнуть следующего уровня Status.',
        ticket_info: 'Цифровой код авиакомпании и 10 цифр номера авиабилета без пробелов',
        flight_number_info: '3 или 4 цифры и буква в конце (при наличии)',
    },
    replanish: {
        title: 'Пополнить счет',
        replanish_value: 'Количество миль',
        error: {
            replanish_value: 'Некорректное количество миль',
            min_value: 'Минимальное количество миль, 500',
        },
    },
    transfer: {
        found: 'Найден',
        user_not_found: 'Пользователь не найден',
        user_not_found_1: 'Возможные причины:',
        user_not_found_2: 'Неверно введены данные.',
        user_not_found_3: 'Пользователь не зарегистрирован в программе Status.',
    },
    combine: {
        title: 'Объединить счета',
        identifier: 'Email, телефон или карта Status',
        button: 'Объединить',
        error: {
            identifier: 'Некорректное значение',
            user_not_found: 'Пользователь не найден',
        },
    },
    fund: {
        title: 'Перевести мили',
        label: 'Введите количество миль',
        button: 'Перевести',
        success: 'Мили успешно переведены в фонд ЮТэйр',
    },
    invite: {
        title: 'Пригласить друга',
        email: 'E-mail друга',
        button: 'Отправить',
        error: {
            email: 'Некорректный адрес эл. почты',
        },
    },
    order: {
        title: 'Заказать физическую карту',
        address: 'Почтовый адрес',
        button: 'Заказать',
        error: {
            email: 'Некорректный адрес',
        },
    },
    errors: {
        unregistered: {
            title: 'Дополните профиль для управления бонусами',
            description1: 'Пожалуйста, заполните ФИО, дату рождения',
            description2: ', e-mail и телефон. Все указанные поля являются обязательными',
            description3: ' и необходимы для активации вашего счета в программе Utair Status.',
            sub1: '* изменение даты рождения возможно только путем обращения в Обратную связь с предоставлением подтверждающих документов',
            sub2: '** для лиц до 14 лет телефон не является обязательным полем',
            action: 'Редактировать профиль',
        },
        blocked: {
            title: 'Ваш счет в программе лояльности Utair Status заблокирован',
            suspended:
                'Мы заметили на счету операции, которые противоречат правилам программы лояльности. Если у вас есть вопросы, обратитесь в Службу поддержки.',
            merged: 'Данный счет был объединен с другим. Если вы не совершали данное действие, обратитесь в Службу поддержки.',
            contactButton: 'Обратиться в Службу поддержки',
        },
        debitMiles: 'У вас отрицательный баланс миль. Лимит кредитования милями достигнут.',
        unknown: {
            action: 'Перейти на главную',
        },
        closed: {
            title: 'Ваша учетная запись заблокирована',
            text: 'Ваша учетная запись была закрыта. Хотите открыть новую учетную запись в бонусной программе Status?',
            addProfileText:
                'Ваша учетная запись была закрыта. Чтобы открыть новую учетную запись в бонусной программе Status вы должны дополнить профиль. Продолжить?',
            yes: 'Да',
            no: 'Нет',
        },
    },
    open: {
        introduction: {
            title: 'Открытие Utair',
            text: 'Получайте мили за любые покупки, доступ в бизнес-залы\nи другие привилегии',
        },
        cards: {
            serve: 'Обслуживание карты\n{{count}} руб. в месяц или ',
            free: 'бесплатно',
            charge: '(1){1 миля начисляется};(2-4){ {{count}} мили начисляется};(0,5-inf){ {{count}} миль начисляется};',
            afterFirstPurchase: 'после первой покупки по карте',
            awardMiles: '(1){1 наградная миля};(2-4){ {{count}} наградные мили};(0,5-inf){ {{count}} наградных миль};',
            award: 'за каждые 50 рублей, оплаченные картой',
            status: 'Статус {{status}}',
            statusText: 'в программе Utair Status',
            order: 'Заказать',
        },
        specialOffer: {
            title: 'Особые условия от Utair',
            figure1: {
                title: 'Промокод на 1500 ₽',
                text: 'купи билет и получи промокод\nна следующую покупку',
            },
            figure2: {
                title: '6 месяцев',
                text: 'бесплатного\nобслуживания карты',
            },
            figure3: {
                title: '15% кешбэк',
                text: 'за первую покупку авиабилета\n(не более 1500 ₽)',
            },
        },
        advantages: {
            title: 'Преимущества карт Открытие',
            figure1: {
                title: 'Персональный менеджер, выделенная телефонная линия',
                text: 'Премиум',
            },
            figure2: {
                title: 'Доступ в бизнес-залы\n ',
                text: 'Премиум, Оптимальный',
            },
            figure3: {
                title: 'Получение статуса SILVER в программе Utair Status',
                text: 'Премиум',
            },
            figure4: {
                title: 'Круглосуточный консьерж - сервис\nи правовая поддержка',
                text: 'Премиум, Оптимальный',
            },
            figure5: {
                title: 'Страховая программа для выезжающих за рубеж',
                text: 'Премиум, Оптимальный',
            },
            figure6: {
                title: 'Снятие наличных в банкоматах любых банков — без комиссии',
                text: 'Премиум, Оптимальный, Базовый',
            },
        },
        calculate: {
            title: 'Рассчитайте ваш доход за год',
            sliderTitle: 'Покупки в год',
            title1: 'Премиум',
            title2: 'Оптимальный',
            title3: 'Базовый',
            miles: '(1){1 миля};(2-4){ {{count}} мили};(0,5-inf){ {{count}} миль};',
            ratio: '(1){1 миля за 50 ₽};(2-4){ {{count}} мили за 50 ₽};(0,5-inf){ {{count}} миль за 50 ₽};',
            welcomeOpen: 'Приветственных миль от Открытия',
            welcomeUtair: 'Приветственных миль от Utair',
            additional: 'Дополнительных карты бесплатно',
            sms: 'SMS-инфо\n в месяц',
        },
        order: {
            title: 'Оформить карту',
        },
        modal: {
            title: 'Преимущества тарифных планов',
            summary: {
                price: 'Бесплатное обслуживание карты при соблюдении минимальных требований (сейчас {{price}} руб. в месяц).',
                withdrawal: 'Снятие наличных в банкоматах любых банков — без комиссии',
                freeTransfer: 'Бесплатный трансфер до/из аэропорта.',
                accessBusinessHall: 'Доступ в бизнес-залы аэропортов.',
            },
            minimalRequirements: {
                title: 'Минимальные требования',
                from: 'от {{count}} ₽',
                balance: 'Сохраняйте остаток на карте',
                shop: 'и совершайте покупки по карте',
                allAccountsBalance: 'Сохраняйте остаток на всех счетах',
            },
            additional: {
                balance: 'среднемесячный остаток',
                shop: 'сумма ежемесячных покупок',
                accounts: 'счет основной карты, вклад, накопительный счет ежемесячных покупок',
            },
        },
    },
    member: 'Участник программ',
    voucher_balance: 'Баланс ваучера',
};
