import moment from 'moment';
import { t } from 'i18next';
import { DATE_MASK } from 'consts';

import { languages } from '../../../locales';
import { DOC_TYPES, RU_DOCUMENTS } from './bookingDocTypes';
import { PASSENGERS_TYPES, RU_COUNTRY_CODE } from './bookingConsts';
import VALIDATION_ERRORS from './validationErrors';
import validator from './bookingValidator';

const { ADULT, CHILD } = PASSENGERS_TYPES;
const { FOREIGN_PASSPORT, BIRTH_CERTIFICATE, NATIONAL_PASSPORT } = DOC_TYPES;

export default {
    validateDocExpiration(docType, docExpiration, lastDepartureDate) {
        if (docType !== FOREIGN_PASSPORT) {
            return null;
        }

        const minDate = lastDepartureDate;
        const maxDate = moment().add(19, 'y').endOf('y').format(DATE_MASK);

        const error = validator.date(docExpiration, true, minDate, maxDate);

        switch (error) {
            case VALIDATION_ERRORS.REQUIRED:
                return t('change_personal_data.errors.document_expiration_required');
            case VALIDATION_ERRORS.INVALID_FORMAT:
            case VALIDATION_ERRORS.INVALID_RANGE_MAX:
                return t('change_personal_data.errors.document_expiration_invalid');
            case VALIDATION_ERRORS.INVALID_RANGE_MIN:
                return t('change_personal_data.errors.document_expired', { departureDate: lastDepartureDate });
            default:
                return null;
        }
    },

    validateBirthday(firstDepartureDate, type, birthday, isAdultWithInfant) {
        const todayDate = moment().format(DATE_MASK);
        const childBoundary = moment(firstDepartureDate, DATE_MASK).subtract(12, 'y').format(DATE_MASK);
        const infantBoundary = moment(firstDepartureDate, DATE_MASK).subtract(2, 'y').add(1, 'd').format(DATE_MASK);
        const adultWithInfantBoundary = moment(firstDepartureDate, DATE_MASK).subtract(18, 'y').format(DATE_MASK);

        let minDate;
        let maxDate;

        if (type === ADULT) {
            minDate = '01.01.1900';
            maxDate = isAdultWithInfant ? adultWithInfantBoundary : childBoundary;
        } else if (type === CHILD) {
            minDate = childBoundary;
            maxDate = todayDate;
        } else {
            minDate = infantBoundary;
            maxDate = todayDate;
        }

        const error = validator.date(birthday, true, minDate, maxDate);

        if (error === VALIDATION_ERRORS.REQUIRED || error === VALIDATION_ERRORS.INVALID_FORMAT) {
            return t(`change_personal_data.errors.birthday_${error}`);
        }

        if (error) {
            if (type === ADULT) {
                if (error === VALIDATION_ERRORS.INVALID_RANGE_MIN) {
                    return t('change_personal_data.errors.birthday_early');
                }

                return isAdultWithInfant
                    ? t('change_personal_data.errors.date_of_birth_adult_with_infant')
                    : t('change_personal_data.errors.date_of_birth_not_adult');
            }

            if (type === CHILD) {
                return error === VALIDATION_ERRORS.INVALID_RANGE_MAX
                    ? t('change_personal_data.errors.birthday_does_not_exist')
                    : t('change_personal_data.errors.date_of_birth_not_child');
            }

            return error === VALIDATION_ERRORS.INVALID_RANGE_MAX
                ? t('change_personal_data.errors.birthday_does_not_exist')
                : t('change_personal_data.errors.date_of_birth_not_infant');
        }

        return null;
    },

    validateDocType(docType, acceptableDocTypes) {
        return !(docType && acceptableDocTypes.includes(docType))
            ? t('change_personal_data.errors.document_required')
            : null;
    },

    validateDocNumber(docNumber, docType) {
        const error = validator.docNumber(docNumber, true, docType);

        return error ? t(`change_personal_data.errors.document_number_${error}`) : null;
    },

    validateDocCountry(docCountry, docType) {
        return !docCountry && !RU_DOCUMENTS.includes(docType)
            ? t('change_personal_data.errors.document_country_required')
            : null;
    },

    validateDocNumberByCountry(docType, docNumber, docCountry) {
        if (docType !== BIRTH_CERTIFICATE) {
            return null;
        }

        if (docCountry === RU_COUNTRY_CODE && !/^[IVXivx]{1,7}[а-я]{2}[0-9]{6}$/i.test(docNumber)) {
            return t('change_personal_data.errors.doc_tip_for_srrf');
        }

        if (!/^[a-zA-Zа-яА-я0-9_]{1,15}$/i.test(docNumber)) {
            return t(`change_personal_data.errors.doc_tip_for_${BIRTH_CERTIFICATE}`);
        }

        return null;
    },

    validateFullName(firstName, sureName, middleName) {
        const fullName = [firstName, sureName, middleName || ''].join('');
        const error = validator.languageUniformity(fullName);

        if (error === VALIDATION_ERRORS.WRONG_LANG) {
            return t('change_personal_data.errors.full_name_wrong_lang');
        }

        return null;
    },

    validateFirstName(firstName, isForeignFlight) {
        const lang = this.getNameLang(isForeignFlight);
        const error = validator.userName(firstName, true, lang);

        return error ? t(`change_personal_data.errors.first_name_${error}`) : null;
    },

    validateLastName(sureName, isForeignFlight) {
        const lang = this.getNameLang(isForeignFlight);
        const error = validator.userName(sureName, true, lang);

        return error ? t(`change_personal_data.errors.last_name_${error}`) : null;
    },

    validateMiddleName(middleName, isForeignFlight) {
        const lang = this.getNameLang(isForeignFlight);
        const error = validator.userName(middleName, false, lang);

        return error ? t(`change_personal_data.errors.middle_name_${error}`) : null;
    },

    getNameLang(isForeignFlight) {
        if (isForeignFlight) {
            return languages.en;
        }

        return null;
    },

    validateBirthdayForDocument(lastDepartureDate, birthday, docType, acceptableDocTypes) {
        const isDocTypeAcceptable = acceptableDocTypes.includes(docType);

        if (!birthday || !isDocTypeAcceptable || docType !== BIRTH_CERTIFICATE) {
            return null;
        }

        const birthDate = moment(birthday, DATE_MASK);
        const adultAge = 14;

        const minBirthdayForBirthCertificate = moment(lastDepartureDate, DATE_MASK)
            .subtract(adultAge, 'y')
            .subtract(30, 'd');
        const isAdultDueDeparture = birthDate.isBefore(minBirthdayForBirthCertificate, 'd');

        if (isAdultDueDeparture) {
            return t('change_personal_data.errors.date_of_birth_invalid_age_for_document', {
                departureDate: lastDepartureDate,
                documentName: t('common.documents.sr'),
                age: adultAge,
            });
        }

        return null;
    },

    getDocNumberTip(docType, docCountry) {
        if (docType === BIRTH_CERTIFICATE && docCountry === RU_COUNTRY_CODE) {
            return t('change_personal_data.errors.doc_tip_for_srrf');
        }

        if (docType === NATIONAL_PASSPORT) {
            return t('change_personal_data.errors.doc_tip_for_np');
        }

        return t(`change_personal_data.errors.doc_tip_for_${docType}`, '');
    },
};
