/* eslint-disable */
/* eslint-disable max-len */

export const REDIRECT_ENABLED = process.env.REDIRECT_ENABLED;
export const BOOKING_URL = process.env.BOOKING_URL;

export const IS_INSURANCE_ENABLED = true;

export const PROGRESS_STEPS = {
    SEARCH: { path: 'search', value: 'search', label: 'Выбор рейса', order: 0 },
    PASSENGERS: { path: 'passengers', value: 'passengers', label: 'Пассажиры', order: 1 },
    CHOOSE_SEAT: { path: 'seats', value: 'choose_seat', label: 'Выбор места', order: 2 },
    SERVICES: { path: 'services', value: 'services', label: 'Услуги', order: 3 },
    PAYMENT: { path: 'payment', value: 'payment', label: 'Оплата', order: 4 },
};

export const ONE_WAY = 'OW';
export const TWO_WAYS = 'RT';

export const DIRECT_TO = 'TO';
export const DIRECT_BACK = 'BACK';

export const DAYS_OF_WEEK = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const NONE_FLIGHTS_TEXT = 'Нет рейсов';

export const CURRENCIES = {
    USD: { value: 'USD', label: '$' },
    EUR: { value: 'EUR', label: '€' },
    RUR: { value: 'RUR', label: '₽' },
};

export const PASSENGERS_TYPES = {
    ADULT: 'adult',
    CHILD: 'child',
    INFANT: 'infant',
};

export const SEX_OPTIONS = {
    [PASSENGERS_TYPES.ADULT]: {
        MALE: { value: 'male', label: 'Мужской' },
        FEMALE: { value: 'female', label: 'Женский' },
    },
    [PASSENGERS_TYPES.CHILD]: {
        MALE: { value: 'male', label: 'Мальчик' },
        FEMALE: { value: 'female', label: 'Девочка' },
    },
};

export const DATE_MASK = 'DD.MM.YYYY';
export const ISO_DATE_MASK = 'YYYY-MM-DD';

export const CLIENT_ID = 'website_client';

export const CLIENT_SECRET = 'nA2REtuw$a-uZ?R3sw&s5A!UW2veDU3U';

const DEFAULT_API_BASE_URL = process.env.NODE_ENV === 'production' ? 'https://b.utair.ru' : 'https://t.utair.ru';
export const API_BASE_URL = process.env.API_BASE_URL || DEFAULT_API_BASE_URL;
const API_CONTENT_BASE_URL = process.env.API_CONTENT_BASE_URL || API_BASE_URL;
export const API_V2 = `${process.env.API_V2}/api/v2`;
export const API_LOYALTY_URL = `${process.env.API_LOYALTY_URL || API_BASE_URL}/api/mcs-loyalty/v1`;

export const API_URLS = {
    AUTH: {
        TOKEN: API_BASE_URL + '/oauth/token',
        AUTHORIZE: API_BASE_URL + '/oauth/authorize',
        LOGIN: API_BASE_URL + '/api/v1/login/',
        LOGIN_CONFIRM: API_BASE_URL + '/api/v1/login/confirm/',
        REVOKE_TOKEN: API_BASE_URL + '/oauth/revoke',
        SOCIAL_LOGIN: API_BASE_URL + '/api/v1/login/social/',
        SOCIAL_REGISTRATION: API_BASE_URL + '/api/v1/profile/social/',
        TAIS_AUTH: 'https://www.utair.ru/ticket/auth_handler.php',
        REREGISTER: API_BASE_URL + '/api/v1/profile/reregistration/',
    },
    REDIRECT: {
        LINK: API_BASE_URL + '/api/v1/ab/',
    },
    SYSTEM: {
        JOIN_CARDS: API_BASE_URL + '/api/v1/system/joinCardsRequest/',
        ORDER_PHYSICAL_CARD: API_BASE_URL + '/api/v1/system/orderPhysicalCard/',
        SUBSCRIPTIONS: `${API_BASE_URL}/api/v1/system/subscriptions/`,
    },
    PROFILE: {
        USER: API_BASE_URL + '/api/v1/profile/',
        COMPANIONS: API_BASE_URL + '/api/v1/profile/companions/',
        GET_CONFIRM: (type) => `${API_BASE_URL}/api/v1/profile/${type}/confirmation/`,
        SESSIONS: `${API_BASE_URL}/api/v1/profile/sessions/`,
        SUBSCRIPTIONS: `${API_BASE_URL}/api/v1/profile/subscriptions/`,
        AVAILABLE_BENEFITS: API_BASE_URL + '/api/v1/profile/available-benefits/',
    },
    FLIGHTS: {
        PAYMENT: API_BASE_URL + '/api/v1/flights/payment/',
        SERVICES: API_BASE_URL + '/api/v1/flights/services/',
        SERVICE_BUSINESS: API_BASE_URL + '/api/v1/flights/services/business/upgrade/',
        ANY_SERVICE: API_BASE_URL + '/api/v1/flights/services/any/',
        START_SESSION: API_BASE_URL + '/api/v1/flights/startsession/',
        GET_OPTIMAL_FARES: API_BASE_URL + '/api/v1/flights/getoptimalfares/',
        GET_AVAILABILITY: API_BASE_URL + '/api/v1/flights/getavailability/',
        SELECT_OFFER: API_BASE_URL + '/api/v1/flights/selectoffer/',
        CREATE_ORDER: API_BASE_URL + '/api/v1/flights/createorder/',
        REGISTER_NEW_USER: API_BASE_URL + '/api/v1/flights/registernewuser/',
        BOOK: API_BASE_URL + '/api/v1/flights/book/',
        GET_PAY_METHODS: API_BASE_URL + '/api/v1/flights/orderpaymethods/',
        SET_PAY_METHOD: API_BASE_URL + '/api/v1/flights/setorderpaymethod/',
        START_PAYMENT: API_BASE_URL + '/api/v1/flights/startpayment/',
        TICKET: API_BASE_URL + '/api/v1/flights/ticket/',
        SEND_TICKET: API_BASE_URL + '/api/v1/flights/sendTicket/',
        GET_ORDER: API_BASE_URL + '/api/v1/flights/order/',
        ORDER_SERVICES: API_BASE_URL + '/api/v1/flights/getorderservices/',
        CONFIRM_REFUND: API_BASE_URL + '/api/v1/flights/confirmrefund/',
        ADD_ORDER_SERVICES: API_BASE_URL + '/api/v1/flights/addorderservice/',
        FOR_MAIN: API_BASE_URL + '/api/v1/flights/forMain/',
        ORDER: (rloc, orderId) => `${API_BASE_URL}/api/v1/flights/order/${orderId}/${rloc.replace(/\/.*/, '')}/`,
        CHECK_UPGRADE: API_BASE_URL + '/api/v1/flights/services/business/check/',
        AVAILABLE_SEATS: API_BASE_URL + '/api/v1/flights/availableSeats/',
        AVAILABLE_FLIGHT_DATES: (departureCityCode, arrivalCityCode) =>
            `${API_BASE_URL}/api/v1/flights/available-flight-dates/?departure=${departureCityCode}&arrival=${arrivalCityCode}`,
    },
    ORDERS: {
        LIST: API_BASE_URL + '/api/v1/orders/',
        ORDER_BY_RLOC_AND_LAST_NAME: (rloc, lastName) =>
            `${API_BASE_URL}/api/v1/orders/${rloc.replace(/\/.*/, '')}/${lastName.trim()}/`,
        ITINERARY_RECEIPT: (orderId, lastName) =>
            `${API_V2}/orders/${orderId}/itinerary_receipt?last_name=${lastName.trim()}`,
        ORDER_BY_UUID: (uuid) => `${API_BASE_URL}/api/v1/orders/${uuid}/`,
        REFUND: (uuid) => `${API_V2}/orders/${uuid}/refund`,
        REFUND_CONFIRM_CODE: `${API_V2}/refunds`,
    },
    BONUSES: {
        BALANCE: API_BASE_URL + '/api/v1/bonus/balance/',
        TRANSACTIONS: `${API_LOYALTY_URL}/transactions/`,
        CREATE_RETRO_CLAIM: API_BASE_URL + '/api/mcs-loyalty/v1/claim/',
        ACCRUAL_BONUS: API_BASE_URL + '/api/v1/bonus/AccrualsBonus/',
        FIND_USER: API_BASE_URL + '/api/v1/bonus/findUser/',
        APPLY_PROMO: API_BASE_URL + '/api/v1/bonus/promocode/',
        TRANSFER_TO_FUND: `${API_LOYALTY_URL}/miles/social/`,
    },
    FAMILY: {
        BALANCE: API_BASE_URL + '/api/v1/bonus/balance/',
        VERIFY: API_BASE_URL + '/api/v1/bonus/verify/',
        ELITE_TIER: API_BASE_URL + '/api/v1/bonus/eliteTier/',
        INVITE_TO_FAMILY: API_BASE_URL + '/api/v1/bonus/inviteUserToFamily/',
        REMOVE_ACCOUNT: API_BASE_URL + '/api/v1/bonus/removeAccountFromHousehold/',
        GET_MEMBERS: API_BASE_URL + '/api/v1/bonus/getHouseholdMembers/',
        GET_INCOMING_REQUESTS: API_BASE_URL + '/api/v1/bonus/listIncomingRequests/',
        GET_OUTGOING_REQUESTS: API_BASE_URL + '/api/v1/bonus/listOutgoingRequests/',
        SET_ACCOUNT_PRIVILEGE: API_BASE_URL + '/api/v1/bonus/setHouseholdAccountPrivilege/',
        ACCRUALS_BALANCE: API_BASE_URL + '/api/v1/bonus/AccrualsBonus/',
        REQUEST_JOIN: API_BASE_URL + '/api/v1/bonus/requestJoinToHousehold/',
        ACCEPT_JOIN: API_BASE_URL + '/api/v1/bonus/acceptJoinToHousehold/',
        DECLINE_JOIN: API_BASE_URL + '/api/v1/bonus/declineJoinToHousehold/',
        REMOVE_JOIN: API_BASE_URL + '/api/v1/bonus/removeJoinRequest/',
        FIND_USER: API_BASE_URL + '/api/v1/bonus/findUser/',
        ACCEPT_INVITE: API_BASE_URL + '/api/v1/bonus/acceptFamilyInvite/',
    },
    REFERRALS: {
        GET_LINK_ID: API_BASE_URL + '/api/v1/referrals/getLinkId/',
        INVITE: API_BASE_URL + '/api/v1/referrals/invite/',
    },
    CHECK_IN: {
        GET_ID: API_BASE_URL + '/api/v1/checkin/dynamicid/',
        PAGE: '//utair.ru/ticket/check-in/',
        SEARCH: API_BASE_URL + '/api/v1/checkin/search/',
        LOADPNR: API_BASE_URL + '/api/v1/checkin/loadpnr/',
        SAVEPAX: API_BASE_URL + '/api/v1/checkin/savepax/',
        BOARDING_PASS: `${API_BASE_URL}/api/v1/checkin/boardingPass/`,
    },
    SCHEDULE: {
        SEARCH: API_BASE_URL + '/api/v1/schedule/search/',
        AIRPORTS: API_BASE_URL + '/api/v1/schedule/airports/',
        SUBSCRIBE: API_BASE_URL + '/api/v1/schedule/subscribe/',
        CONFIRM: API_BASE_URL + '/api/v1/schedule/confirm/',
    },
    PAYMENT: API_BASE_URL + '/api/v1/payments/card/',
    PAYMENT_CARD_BY_ID: (cardId) => API_BASE_URL + `/api/v1/payments/card/${cardId}/`,
    TRANSACTIONS: API_BASE_URL + '/api/v1/transactions/',
    AIRPORTS: API_BASE_URL + '/api/v1/airports/',
    CITIES: {
        ALL: API_BASE_URL + '/cities/api/v3/cities',
        NEAREST: API_BASE_URL + '/api/v1/cities/nearest/',
    },
    PROMO: {
        CHECK: API_BASE_URL + '/api/v1/promo/check/',
        APPLY: API_BASE_URL + '/api/v1/promo/apply/',
    },
    NEW_YEAR: {
        GIFT: API_BASE_URL + '/api/v1/prepaid/',
        PRICES: API_BASE_URL + '/api/v1/prepaid/prices/',
        PRICE: API_BASE_URL + '/api/v1/prepaid/price/',
        VERIFY: API_BASE_URL + '/api/v1/prepaid/verify/',
        CONFIRM: API_BASE_URL + '/api/v1/prepaid/confirm/',
        REDEEM: API_BASE_URL + '/api/v1/prepaid/redeem/',
    },
    NOTIFICATIONS: {
        GET: API_BASE_URL + '/api/v1/system/client/notifications/',
    },
    MENU: {
        GET_CORE_MENU: 'https://www.utair.ru/api/menu/getCoreMenu/',
    },
    COMPANIES: {
        BASE: `${API_BASE_URL}/api/v1/companies/`,
        BY_USER: `${API_BASE_URL}/api/v1/companies/byUser/`,
        EMPLOYEE: `${API_BASE_URL}/api/v1/companies/employee/`,
        EMPLOYEE_HASH: `${API_BASE_URL}/api/v1/companies/employee/hash/`,
        ACCEPT_INVITE: `${API_BASE_URL}/api/v1/companies/acceptInvite/`,
        REJECT_INVITE: `${API_BASE_URL}/api/v1/companies/rejectInvite/`,
        LEAVE: `${API_BASE_URL}/api/v1/companies/leave/`,
        CHECK_USERS: `${API_BASE_URL}/api/v1/companies/check_employees/`,
        TRANSACTIONS: `${API_BASE_URL}/api/v1/companies/transactions/`,
    },
    ADDITIONAL_PURCHASE: {
        AVAILABLE_SERVICES: API_CONTENT_BASE_URL + '/asm/api/v1/services/',
        CART: API_CONTENT_BASE_URL + '/asm/api/v1/services/cart/',
        PAYMENT_METHODS: (cartId) => API_CONTENT_BASE_URL + `/asm/api/v1/services/cart/${cartId}/paymentMethods/`,
        PAYMENT: (cartId) => API_CONTENT_BASE_URL + `/asm/api/v1/services/cart/${cartId}/payment/`,
        PAYMENT_V2: (cartId) => API_CONTENT_BASE_URL + `/asm/api/v2/services/cart/${cartId}/payment/`,
        DISCOUNT: (cartId, type, value) =>
            API_CONTENT_BASE_URL + `/asm/api/v1/services/cart/${cartId}/discount/?type=${type}&value=${value}`,
        SEAT_MAP: API_CONTENT_BASE_URL + '/asm/api/v1/seat-map/',
    },
    SUPPLEMENTS: {
        ORDERS_BY_LOCATOR_AND_LAST_NAME: (locator, lastName) =>
            `${API_BASE_URL}/api/v1/supplements/orders/${locator}/${lastName}/`,
        GET_CODE_BY_RLOC_AND_LAST_NAME: (rloc, lastName) =>
            `${API_BASE_URL}/api/v1/supplements/orders/${rloc}/${lastName}/refund/start/`,
        CONFIRM_CODE: (rloc, lastName) =>
            `${API_BASE_URL}/api/v1/supplements/orders/${rloc}/${lastName}/refund/confirm/`,
        CONTACTS_BY_RLOC_AND_LAST_NAME: (rloc, lastName) =>
            `${API_BASE_URL}/api/v1/supplements/orders/${rloc}/${lastName}/refund/`,
        ACTIVATE_ACCOUNT: (rloc, lastName) =>
            `${API_BASE_URL}/api/v1/supplements/orders/${rloc}/${lastName}/refund/activate/`,
        SEND_NOTIFICATION: (rloc, lastName) =>
            `${API_BASE_URL}/api/v1/supplements/orders/${rloc}/${lastName}/refund/notifications/`,
        GET_ATTEMPT_ID: (orderUUID) => `${API_V2}/orders/${orderUUID}/refund`,
        SEND_CONFIRM_CODE: `${API_V2}/orders/refunds`,
    },
    ORDERS_V2: {
        LIST: `${API_V2}/users/orders`,
        CHECKIN: `${API_V2}/users/orders/checkin`,
        LIST_APP: `${API_V2}/orders`,
        CHECKIN_APP: `${API_V2}/orders/checkin`,
        DELETE: (orderId) => `${API_V2}/users/orders/${orderId}`,
        REFUND: (orderUUID) => `${API_V2}/orders/${orderUUID}/refund`,
        ORDER_BY_UUID: (orderUUID) => `${API_V2}/orders?filters[uuid]=${orderUUID}`,
        PD_CHANGE: (orderUUID, passengerId) => `${API_V2}/orders/${orderUUID}/passengers/${passengerId}/personal_data`,
        PD_CHANGE_CREATE_PAYMENT: `${API_V2}/orders/passengers/personal_data`,
        WITHDRAW: (orderUUID) => `${API_V2}/orders/${orderUUID}/withdraw`,
        WITHDRAW_SUBMIT: `${API_V2}/orders/withdraw/submit`,
        RECEIPTS_LIST: (orderUUID) => `${API_V2}/orders/${orderUUID}/receipts`,
    },
    SUPPORT: {
        FORM: (issueTypeId) => `${API_BASE_URL}/api/content/v1/forms/${issueTypeId}/`,
    },
    COUNTRIES: API_BASE_URL + '/api/v1/countries/',
    ONLINE_PANEL: API_BASE_URL + '/api/v1/flights/schedule/',
};

const getOriginUrl = () => window.location.origin;

export const getBookingUrl = (url = '', params = '', lng = 'ru') => {
    if (lng === 'en') {
        return getTaisUrl(url, params, lng);
    }

    const urlLng = lng === 'ru' ? '' : `/${lng}`;
    const origin = BOOKING_URL || getOriginUrl();

    if (url === '') {
        return `${origin}${urlLng}/${params ? '?' + params.replace('?', '') : ''}`;
    }

    return `${origin}${urlLng}/booking/search/${url}${params ? '?' + params.replace('?', '') : ''}`;
};

export const getTaisUrl = (url = '', params = '', lng = 'ru') => {
    const origin = getOriginUrl();

    return `${origin}/${lng === 'en' ? 'en/' : ''}ticket/${url}?switch_site=1${
        params ? '&' + params.replace('?', '') : ''
    }`;
};

export const SBER_SPASIBO_URL =
    'https://spasibosberbank.ru/coupon/puteshestviya/novye-privilegii-utair-status-za-bonusy';

/*
 TODO в новой обратной связи пока нет английской версии,
 поэтому времмено убрал из урла язык. После добавления английской версии
 нужно будет вернуть возможность переключения языка.
*/
export const getFeedbackUrl = (lng = 'ru') => `https://utair.ru/support`;

export const getKopikotUrl = (statusCardNumber) => `https://bonus.utair.ru/${statusCardNumber}`;

export const getChildsRulesUrl = () => 'https://www.utair.ru/support/8/mozhet_li_rebenok_letet_odin';

export const URLS = {
    CHECK_IN: (params, lng) => getTaisUrl('check-in/', params, lng),
    BOOKING_FALLBACK: 'https://utair.ru/ticket',
};

export const LOCAL_STORAGE = {
    SESSION_TOKEN: 'session_token',
    ORDER_ID: 'order_id',
};

export const I18N_PATH = '../locales/{{lng}}/{{ns}}';

export const GENDER = {
    MALE: { CODE: 'm', LABEL: 'Мужской' },
    FEMALE: { CODE: 'f', LABEL: 'Женский' },
};

export const FB_CONFIG = {
    APP_ID: '1432903466774269',
    REDIRECT_URI: 'http://utair.raketa.im/',
    VERSION: '2.9',
    PICTURE_SIZE: '200',
};

export const DADATA_APIKEY = '0086c090b30178e318b6e55c9f2d93f54c0a237c';

export const DADATA = {
    ADDRESS: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
    NAME: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio',
    COMPANY: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party',
};

export const PRIVATE_CACHE_FLAG = 'private';

export const BOOKING_DOCUMENTS_CACHE_KEYNAME = 'documents';

export const INPUT_DEBOUNCE_TIMEOUT = 200;

export const SUBSCRIPTIONS_CATEGORIES = ['advertisement'];

export const USER_CATEGORIES = {
    STANDBY: [
        'utairsa-it',
        'utairsa-pao',
        'utairsa-heli',
        'utairsa-engineer',
        'utairsa-tech',
        'utairsa-ao',
        'utairsa-turuh',
    ],
};

export const DEFAULT_UID = null;

export const GDPR_AGREE_TEXT =
    'Я ознакомлен с условиями использования моих персональных данных и даю согласие на их обработку.';

export const PERSONAL_DATA_FILE_NAME = 'personal.json';

export const CLIENT_ERROR_TYPE = 'clientError';

export const EMAIL_REGEXP = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,15})$/i;

export const LOYALTY_CARD = /^(\d{10,11}|\d{13}|\d{16})$/;

export const CAPTCHA_KEY = '6Lc_4asUAAAAANLBRFZfS9kcsu5BhW3bxsS5TZo9';

export const CAPTCHA_ACTION = 'homepage';

export const CAPTCHA_FAIL_TOKEN =
    '03AOLTBLShUriffWkZRwrLkZ-iB1cYwzqV0FK0cGX-gX7rbMgkPWUFMEQfznKALmOU5-oC7rymm2zt_DagzTIxtsf-UeaoX_Ttwe1QX1ccvrQIbPnXjqUAYzkoF-wupTywnRJMnJ3KMhzN_cHy-QR_J5bj2PsVmZd5L-BT26uIB9KAOWLrkHx_iRZ39nJuQMiguq4z5r-GzoL1iOqdEcHtuwMU5UZDsrmlCSF34tklFGBtqYB1fGsDSxtlWIi_eBPrInmquiB24Q4sC_zHZE9qk6ncm1EEmjV_ghuw1rsRX5Z_FaBN7kIP51zv148bgTNDsVPDi79w9Pny';

// все билетвы купленные в условиях карантина, с 1 апреля 2020 года дня считаются возвратными
export const QUARANTINE_START_TIME_STAMP = 1585699200;
export const QUARANTINE_END_TIME_STAMP = 1594080000;

export const DELAY = 300;

export const TELEGRAM_BOT = 'utair_support_bot';

export const REDIRECT_LINKS = {
    ROOT: '/',
    USER: '/user',
    USER_MOBILE: '/m/user',
    BONUSES: '/bonuses',
    BONUSES_TRANSACTIONS: '/bonuses/transactions',
    TICKETS: '/tickets',
    TICKETS_MOBILE: '/m/tickets',
    CHECK_IN: '/check-in',
    CHECK_IN_MOBILE: '/m/check-in',
    CHECK_IN_NEW: '/check-in-new',
    UTAIR_STATUS: 'https://media.utair.ru/status',
    ORDER_MANAGE: '/order-manage',
    HELICOPTERS: '/helicopters',
};

// минимальная сумма заказа, которую пользователь должен оплатить рублями
export const MINIMAL_REAL_MONEY_PRICE = 299;

export const MODES = {
    REFUND: 'refund',
    RELEASE_SEATS: 'release_seats',
    CHANGE_PD: 'change_pd',
};
