import React, { createContext, useContext, useState, useMemo } from 'react';

export function createContextStore(initialState = []) {
    const ActionContext = createContext(null);
    const DataContext = createContext(null);
    const QueryContext = createContext(null);

    function useQuery() {
        return useContext(QueryContext);
    }

    function useData() {
        return useContext(DataContext);
    }

    function useAction() {
        return useContext(ActionContext);
    }

    function ContextProvider({ children }) {
        const [data, setData] = useState(initialState);
        const [isLoading, setIsLoading] = useState(false);
        const [error, setError] = useState(null);

        const action = useMemo(() => ({ setData, setIsLoading, setError }), []);

        return (
            <ActionContext.Provider value={action}>
                <QueryContext.Provider value={{ isLoading, error }}>
                    <DataContext.Provider value={data}>{children}</DataContext.Provider>
                </QueryContext.Provider>
            </ActionContext.Provider>
        );
    }

    function withContext(Component) {
        return function Context(props) {
            return (
                <ContextProvider>
                    <Component {...props} />
                </ContextProvider>
            );
        };
    }

    return { useQuery, useData, useAction, ContextProvider, withContext };
}
