import { useMemo } from 'react';
import moment from 'moment';
import i18next from 'i18next';
import { intersectionWith, isEqual, some, every, pickBy, identity, mapKeys, invert, sortBy } from 'lodash';
import { DATE_MASK, ISO_DATE_MASK } from 'consts';

import {
    PASSENGERS_TYPES,
    RU_COUNTRY_CODE,
    COUNTRY_CODES_WITH_RU_PASSPORT,
    COUNTRY_CODES_WITH_RU_DOCS,
} from './bookingConsts';
import {
    ALL_ADULT_DOC_TYPES,
    ALL_CHILD_DOC_TYPES,
    FOREIGN_ADULT_DOC_TYPES_WITH_RU_DOCS,
    FOREIGN_ADULT_DOC_TYPES_WITH_RU_PASSPORT,
    FOREIGN_ADULT_DOC_TYPES,
    RU_DOCUMENTS,
    NOT_RU_DOCUMENTS,
} from './bookingDocTypes';
import bCValidator from './bookingComponentValidator';

const { ADULT } = PASSENGERS_TYPES;

export function getIsAdultPassenger(type, birthday, lastDepartureDate) {
    const momentBirthday = moment(birthday, DATE_MASK);

    if (!momentBirthday.isValid()) {
        return type === ADULT;
    }

    const adultBirthday = moment(lastDepartureDate, DATE_MASK).subtract(14, 'y').add(1, 'd');
    const isAdult = momentBirthday.isBefore(adultBirthday, 'd');

    return type === ADULT && (!birthday || isAdult);
}

function getAdultAcceptableDocTypes(isForeignFlight, isForeignFlightWithRuPassport, isForeignFlightWithRuDocs) {
    if (isForeignFlight) {
        if (isForeignFlightWithRuDocs) {
            return FOREIGN_ADULT_DOC_TYPES_WITH_RU_DOCS;
        }

        if (isForeignFlightWithRuPassport) {
            return FOREIGN_ADULT_DOC_TYPES_WITH_RU_PASSPORT;
        }

        return FOREIGN_ADULT_DOC_TYPES;
    }

    return ALL_ADULT_DOC_TYPES;
}

export function getAcceptableDocTypes(
    isAdultPassenger,
    isForeignFlight,
    isForeignFlightWithRuPassport,
    isForeignFlightWithRuDocs
) {
    if (isAdultPassenger) {
        return getAdultAcceptableDocTypes(isForeignFlight, isForeignFlightWithRuPassport, isForeignFlightWithRuDocs);
    }

    if (isForeignFlight) {
        const adultDocTypes = getAdultAcceptableDocTypes(
            isForeignFlight,
            isForeignFlightWithRuPassport,
            isForeignFlightWithRuDocs
        );

        return intersectionWith(ALL_CHILD_DOC_TYPES, adultDocTypes, isEqual);
    }

    return ALL_CHILD_DOC_TYPES;
}

export const getIsForeignFlight = (segments) => {
    // В букинге это сегменты "туда", в управлении билетом слишком сложно получить направления.
    const isForeign = (segment) =>
        segment.departure_country.code !== RU_COUNTRY_CODE || segment.arrival_country.code !== RU_COUNTRY_CODE;

    return some(segments, isForeign);
};

// DEPRECATED
export const getIsForeignFlightWithRuPassport = (segments) => {
    // В букинге это сегменты "туда", в управлении билетом слишком сложно получить направления.
    const isForeign = (segment) =>
        COUNTRY_CODES_WITH_RU_PASSPORT.includes(segment.departure_country.code) &&
        COUNTRY_CODES_WITH_RU_PASSPORT.includes(segment.arrival_country.code);

    return every(segments, isForeign);
};

// DEPRECATED
export const getIsForeignFlightWithRuDocs = (segments) => {
    // В букинге это сегменты "туда", в управлении билетом слишком сложно получить направления.
    const isForeign = (segment) =>
        COUNTRY_CODES_WITH_RU_DOCS.includes(segment.departure_country.code) &&
        COUNTRY_CODES_WITH_RU_DOCS.includes(segment.arrival_country.code);

    return every(segments, isForeign);
};

export function getDocumentExtraField(docType) {
    if (!RU_DOCUMENTS.includes(docType)) return 'docCountry';

    if (docType === 'psp') return 'docExpiration';

    return null;
}

export function validateDocument(
    { docType, docNumber, docCountry, docExpiration, birthday },
    acceptableDocTypes,
    lastDepartureDate,
    getCountryOptionsByDocType
) {
    const errors = {};
    const extraField = getDocumentExtraField(docType);

    errors.docType =
        bCValidator.validateDocType(docType, acceptableDocTypes) ||
        bCValidator.validateBirthdayForDocument(lastDepartureDate, birthday, docType, acceptableDocTypes);

    errors.docNumber =
        (docNumber
            ? bCValidator.validateDocNumberByCountry(docType, docNumber, docCountry)
            : bCValidator.getDocNumberTip(docType, docCountry)) || bCValidator.validateDocNumber(docNumber, docType);

    if (extraField === 'docCountry') {
        errors.docCountry = bCValidator.validateDocCountry(
            getCountryOptionsByDocType(docType).some((option) => option.value === docCountry) && docCountry,
            docType
        );
    }

    if (extraField === 'docExpiration') {
        errors.docExpiration = bCValidator.validateDocExpiration(docType, docExpiration, lastDepartureDate);
    }

    errors.birthday = bCValidator.validateBirthdayForDocument(lastDepartureDate, birthday, docType, acceptableDocTypes);

    return pickBy(errors, identity);
}

function mapCountryWiithCyrillicName({ name, nameCyrillic, ...rest }) {
    return { ...rest, name: nameCyrillic || name };
}

function getSortedCountries(countries) {
    return sortBy(countries.map(mapCountryWiithCyrillicName), [
        (country) => (country.code2 === RU_COUNTRY_CODE ? 0 : 1),
        'name',
    ]);
}

function getCountriesWithoutRu(sortedCountries) {
    return sortedCountries.filter((country) => country.code2 !== RU_COUNTRY_CODE);
}

function mapCountryOption({ name, code2 }) {
    return { label: name, value: code2 };
}

function getCountryOptions(sortedCountries, countriesWithoutRu) {
    return {
        default: sortedCountries.map(mapCountryOption),
        withoutRu: countriesWithoutRu.map(mapCountryOption),
    };
}

function checkFullNameDuplication(pax, pax2) {
    return ['firstName', 'lastName', 'secondName'].every((name) => (pax[name] || '') === (pax2[name] || ''));
}

function validateFullNameDuplication(values, otherPassengers) {
    const hasDuplication = otherPassengers.some((pax) => checkFullNameDuplication(pax, values));

    return hasDuplication ? i18next.t('change_personal_data.errors.full_name_duplicate') : null;
}

export function useFormHelper(passenger, segments, otherPassengers, countries) {
    const [firstDepartureDate, lastDepartureDate] = useMemo(() => {
        const moments = segments.map((segment) => moment(segment.departureDateIso, ISO_DATE_MASK));

        return [moment.min(moments).format(DATE_MASK), moment.max(moments).format(DATE_MASK)];
    }, [segments]);

    const isAdultWithInfant = useMemo(() => {
        const hasChild = otherPassengers.some(({ type }) => type === PASSENGERS_TYPES.CHILD);
        const infantCount = otherPassengers.filter(({ type }) => type === PASSENGERS_TYPES.INFANT).length;

        if (!hasChild && !infantCount) return false;

        const adultWithInfantCount = otherPassengers.filter(
            ({ type, birthday }) =>
                type === PASSENGERS_TYPES.ADULT &&
                moment(birthday, ISO_DATE_MASK).add(18, 'y').isBefore(moment(firstDepartureDate, DATE_MASK))
        ).length;

        return adultWithInfantCount < (infantCount || +hasChild);
    }, [firstDepartureDate, otherPassengers]);

    const countryOptions = useMemo(() => {
        const sortedCountries = getSortedCountries(countries);
        const countriesWithoutRu = getCountriesWithoutRu(sortedCountries);

        return getCountryOptions(sortedCountries, countriesWithoutRu);
    }, [countries]);

    return useMemo(() => {
        const { type, birthday } = passenger;
        const isAdultPassenger = getIsAdultPassenger(type, birthday, lastDepartureDate);
        const isForeignFlight = getIsForeignFlight(segments);
        const isForeignFlightWithRuPassport = getIsForeignFlightWithRuPassport(segments);
        const isForeignFlightWithRuDocs = getIsForeignFlightWithRuDocs(segments);
        const acceptableDocTypes = getAcceptableDocTypes(
            isAdultPassenger,
            isForeignFlight,
            isForeignFlightWithRuPassport,
            isForeignFlightWithRuDocs
        );
        const documentOptions = acceptableDocTypes.map((value) => ({
            label: i18next.t(`common.documents.${value}`),
            value,
        }));
        const getCountryOptionsByDocType = (docType) =>
            NOT_RU_DOCUMENTS.includes(docType) ? countryOptions.withoutRu : countryOptions.default;
        const validators = {
            validateLastName: (lastName) => bCValidator.validateLastName(lastName, isForeignFlight),
            validateFirstName: (firstName) => bCValidator.validateFirstName(firstName, isForeignFlight),
            validateMiddleName: (values) =>
                bCValidator.validateMiddleName(values.secondName, isForeignFlight) ||
                validateFullNameDuplication(values, otherPassengers),
            validateBirthday: (birthdayValue) =>
                bCValidator.validateBirthday(firstDepartureDate, type, birthdayValue, isAdultWithInfant),
            validateFullName: (lastName, firstName, secondName) =>
                !(lastName || firstName || secondName)
                    ? null
                    : bCValidator.validateFullName(lastName, firstName, secondName),
            validateDocument: (values) =>
                validateDocument(values, acceptableDocTypes, lastDepartureDate, getCountryOptionsByDocType),
        };

        return {
            isForeignFlight,
            firstDepartureDate,
            lastDepartureDate,
            acceptableDocTypes,
            documentOptions,
            validators,
            getDocumentExtraField,
            getCountryOptionsByDocType,
        };
    }, [
        firstDepartureDate,
        lastDepartureDate,
        isAdultWithInfant,
        otherPassengers,
        passenger,
        segments,
        countryOptions,
    ]);
}

export function mapDataKeys(data) {
    const keys = {
        first_name: 'firstName',
        last_name: 'lastName',
        second_name: 'secondName',
        doccountry: 'docCountry',
        docexpiration: 'docExpiration',
        docnumber: 'docNumber',
        doctype: 'docType',
        order_uuid: 'orderUUID',
        passenger_id: 'passengerId',
    };
    const keysMap = { ...keys, ...invert(keys) };

    return mapKeys(data, (_, key) => keysMap[key] || key);
}
