import { isEmpty, get } from 'lodash';

import { AIRPORT_TYPES_VALUES } from './conts';

export function prepareCity(city, language) {
    if (city) {
        let name = city.name_translations ? city.name_translations[language] : city.name || '';
        let { code } = city;
        const country = get(city, `country_name_translations[${language}]`, city.country_name);

        if (!isEmpty(city.airport)) {
            code = city.airport.code;

            if (city.airport.name_translations && city.airport.name_translations[language]) {
                name = `${city.airport.name_translations[language]},  ${name}`;
            }
        }

        return { ...city, country, code, name };
    }

    return null;
}

export function prepareCitiesList(cities, lang) {
    if (cities && cities.length) {
        return cities.map((city) => prepareCity(city, lang)).slice(0, 7);
    }

    return [];
}

export function findAvailableAirportTypes(airports = []) {
    return airports.reduce((acc, { tags }) => {
        tags?.forEach((tag) => {
            if (acc.includes(tag) || !AIRPORT_TYPES_VALUES.includes(tag)) return;

            acc.push(tag);
        });

        return acc;
    }, []);
}
